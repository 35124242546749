import React, { useState, useEffect, useContext, createContext } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { AuthContext } from 'src/contexts/AuthProvider'

const CommonContext = createContext()

const CommonProvider = (props) => {
  const { user, username, profile } = useContext(AuthContext)
  const [IP, setIP] = useState(null)
  const [path, setPath] = useState()
  const [variation, setVariation] = useState(false)
  const [variants, setVariants] = useState(null)
  const [variant, setVariant] = useState(null)
  const [options, setOptions] = useState(null)
  const [media, setMedia] = useState(null)

  const [filter, setFilter] = useState({
    disjunctive: 'and',
    rules: [{ column: 'status', relation: '%_%', condition: 'active, draft', type: 'array' }],
    sort: 'CREATED_DESC',
  })
  const [disjunctive, setDisjunctive] = useState('and')
  const [rules, setRules] = useState([
    { column: 'status', relation: '%_%', condition: 'active, draft', type: 'array' },
  ])
  const [sort, setSort] = useState('CREATED')
  const [order, setOrder] = useState('DESC')

  const [refinement, setRefinement] = useState(null)
  const [total, setTotal] = useState(0)
  const [count, setCount] = useState(0)

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)

  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(0)

  const [alertDelay, setAlertDelay] = useState(false)
  const [alertError, setAlertError] = useState(false)

  const [users, setUsers] = useState()

  const combinations = () => {
    const combine = (a1, a2) => {
      return a1.flatMap((u) => a2.map((v) => [u, v].flat()))
    }
    if (options) {
      const o = options.map((o) => o.values).filter((v) => v.length > 0)
      if (o.length > 0) {
        let array = o[0]
        for (let i = 1; i < o.length; i++) {
          array = combine(array, o[i])
        }
        return array
      }
    }
    return []
  }

  const fetchUsers = async () => {
    try {
      const url = `${process.env.REACT_APP_API}/support/users`
      const { data } = await axios.get(url, {
        params: {
          user: String(user),
        },
      })
      setUsers(data.users)
    } catch (error) {
      console.error('Error fetching collections: ', error)
    }
  }

  const fetchIP = async () => {
    const { data } = await axios.get('https://api.ipify.org/?format=json')
    setIP(data.ip)
  }

  const upload = (files, location) => {
    return new Promise((resolve, reject) => {
      const form = new FormData()
      files.forEach((e) => form.append('files', e))
      form.append('location', location)
      form.append('cognito', username)
      form.append('user', user)
      const url = `${process.env.REACT_APP_API}/media`
      setLoaded(0)

      axios
        .post(url, form, {
          headers: {
            'content-type': 'multipart/form-data',
          },
          onUploadProgress: (e) => {
            setLoaded(Math.round((e.loaded * 100) / e.total))
            if (e.loaded === e.total) {
              setLoading(true)
              setLoaded(0)
            }
          },
        })
        .then((res) => {
          resolve({ result: res.data, error: {} })
          setLoading(false)
        })
        .catch((error) => {
          if (loaded) setAlertError(true)
          else setAlertDelay(true)
          setLoading(false)
          reject({ result: {}, error: error })
        })
    })
  }

  useEffect(() => {
    if (user) {
      if (profile.email === process.env.REACT_APP_SUPPORT) {
        fetchUsers()
      }
    }
  }, [user])

  useEffect(() => {
    fetchIP()
  }, [])

  return (
    <CommonContext.Provider
      value={{
        IP,
        users,

        path,
        setPath,
        variation,
        setVariation,
        variants,
        setVariants,
        variant,
        setVariant,
        options,
        setOptions,
        media,
        setMedia,

        refinement,
        setRefinement,
        filter,
        setFilter,
        disjunctive,
        setDisjunctive,
        rules,
        setRules,
        sort,
        setSort,
        order,
        setOrder,
        combinations,

        limit,
        setLimit,
        offset,
        setOffset,
        total,
        setTotal,
        count,
        setCount,
        page,
        setPage,

        upload,
        loading,
        loaded,

        alertDelay,
        setAlertDelay,
        alertError,
        setAlertError,
      }}
    >
      {props.children}
    </CommonContext.Provider>
  )
}

CommonProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export { CommonProvider, CommonContext }
