import React, { useContext, Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'
import { AuthContext } from './contexts/AuthProvider'
import loading from './assets/images/loading-light.svg'

// Containers
const Layout = React.lazy(() => import('./components/layout/Layout'))

// Pages
const Login = React.lazy(() => import('./pages/login/Login'))
const Register = React.lazy(() => import('./pages/register/Register'))
const Confirm = React.lazy(() => import('./pages/register/Confirm'))
const Page = React.lazy(() => import('./pages/page200/Page'))
const Page404 = React.lazy(() => import('./pages/page404/Page404'))
const Page500 = React.lazy(() => import('./pages/page500/Page500'))

const App = () => {
  const { isAuthenticated, isReady } = useContext(AuthContext)

  return isReady ? (
    <BrowserRouter>
      <Suspense fallback={<div style={{ backgroundImage: `url('${loading}')` }} />}>
        <Routes>
          <Route path="/confirmuser" name="Confirm User" element={<Confirm />} />
          <Route
            exact
            path="/login"
            name="Login Page"
            element={isAuthenticated ? <Navigate replace to="/" /> : <Login />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            element={isAuthenticated ? <Navigate replace to="/" /> : <Register />}
          />
          <Route exact path="/pages/*" name="Page" element={<Page />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            path="*"
            name="Home"
            element={isAuthenticated ? <Layout /> : <Login /> /*<Navigate replace to="/login" />*/}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  ) : (
    <div className="min-vh-100 bg-light bg-gradient"></div>
  )
}

export default App
